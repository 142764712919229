// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes shake {
  0% { transform: translateX(0); }
  20% { transform: translateX(-20px); }
  40% { transform: translateX(20px); }
  60% { transform: translateX(-20px); }
  80% { transform: translateX(20px); }
  100% { transform: translateX(0); }
}

.shake {
  animation: shake 0.5s ease-in-out; /* Cambia 0.5s a 1.5s o más */
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,KAAK,wBAAwB,EAAE;EAC/B,MAAM,4BAA4B,EAAE;EACpC,MAAM,2BAA2B,EAAE;EACnC,MAAM,4BAA4B,EAAE;EACpC,MAAM,2BAA2B,EAAE;EACnC,OAAO,wBAAwB,EAAE;AACnC;;AAEA;EACE,iCAAiC,EAAE,6BAA6B;AAClE","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n@keyframes shake {\n  0% { transform: translateX(0); }\n  20% { transform: translateX(-20px); }\n  40% { transform: translateX(20px); }\n  60% { transform: translateX(-20px); }\n  80% { transform: translateX(20px); }\n  100% { transform: translateX(0); }\n}\n\n.shake {\n  animation: shake 0.5s ease-in-out; /* Cambia 0.5s a 1.5s o más */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
